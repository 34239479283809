import { Layout } from ".";
import { LeadSection, Post } from "@lleed-partners/factor";
import { IChildrenElementProps } from "@lleed-partners/factor/src/typings/props";

export const PageLayout = ({
  children,
  title,
}: IChildrenElementProps & { title: string }) => {
  return (
    <Layout
      navProps={{
        showSubNav: false,
      }}
    >
      <div
        style={{
          height: "8rem",
        }}
      ></div>
      <Post>{children}</Post>
      <div
        style={{
          height: "8rem",
        }}
      ></div>
    </Layout>
  );
};
