import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { PageLayout } from "../layouts/page";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PageLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1 {...{
      "id": "mentions-légales"
    }}>{`Mentions légales`}</h1>
    <h2 {...{
      "id": "entité"
    }}>{`Entité`}</h2>
    <p>{`Lleed & Partners SàRL est une société de droit suisse à responsabilité limitée. Son siège est situé Rue de la cité 1, C/O Multiburo, 1204 Genève, Suisse. Son capital social actuel est de CHF 20,000.-.`}</p>
    <p>{`Lleed & Partners est enregistrée au registre du commerce de Genève et de la TVA suisse sous l'identifiant CHE-279.374.453 depuis le 31 août 2020. Plus d'informations sont disponibles auprès de `}<a parentName="p" {...{
        "href": "https://ge.ch/hrcintapp/externalCompanyReport.action?companyOfsUid=CHE-279.374.453&lang=FR"
      }}>{`ge.ch`}</a></p>
    <p>{`Pour toute question concernant ce site web et son contenu, merci d'utiliser notre `}<a parentName="p" {...{
        "href": "/contact/"
      }}>{`portail de contact`}</a>{`.`}</p>
    <h2 {...{
      "id": "hébergement"
    }}>{`Hébergement`}</h2>
    <p>{`Ce site web est hébergé par Netlify, Inc., basée au 2325 3rd Street, Suite 296, San Francisco, California 94107, United States. Vous pouvez contacter Netlify par courrier à l'adresse ci-présente, ou par courriel à l'addresse `}<a parentName="p" {...{
        "href": "mailto:support@netlify.com"
      }}>{`support@netlify.com`}</a>{`.`}</p>
    <h2 {...{
      "id": "propriété-intellectuelle"
    }}>{`Propriété intellectuelle`}</h2>
    <p>{`Ce site web eest couvert par la license `}<a parentName="p" {...{
        "href": "https://creativecommons.org/licenses/by-nc-nd/4.0/legalcode.fr"
      }}>{`Creative Commons CC BY-NC-ND 4.0 license`}</a>{`. Le contenu et les images peuvent être couverts par une autre license.`}</p>
    <p>{`Pour toute demande concernant une violation de copyright, merci d'utiliser notre `}<a parentName="p" {...{
        "href": "/contact/"
      }}>{`portail de contact`}</a>{`.`}</p>


    <style {...{
      "className": "grvsc-styles"
    }}>{`
  .grvsc-container {
    overflow: auto;
    position: relative;
    -webkit-overflow-scrolling: touch;
    padding-top: 1rem;
    padding-top: var(--grvsc-padding-top, var(--grvsc-padding-v, 1rem));
    padding-bottom: 1rem;
    padding-bottom: var(--grvsc-padding-bottom, var(--grvsc-padding-v, 1rem));
    border-radius: 8px;
    border-radius: var(--grvsc-border-radius, 8px);
    font-feature-settings: normal;
    line-height: 1.4;
  }
  
  .grvsc-code {
    display: table;
  }
  
  .grvsc-line {
    display: table-row;
    box-sizing: border-box;
    width: 100%;
    position: relative;
  }
  
  .grvsc-line > * {
    position: relative;
  }
  
  .grvsc-gutter-pad {
    display: table-cell;
    padding-left: 0.75rem;
    padding-left: calc(var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem)) / 2);
  }
  
  .grvsc-gutter {
    display: table-cell;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  
  .grvsc-gutter::before {
    content: attr(data-content);
  }
  
  .grvsc-source {
    display: table-cell;
    padding-left: 1.5rem;
    padding-left: var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem));
    padding-right: 1.5rem;
    padding-right: var(--grvsc-padding-right, var(--grvsc-padding-h, 1.5rem));
  }
  
  .grvsc-source:empty::after {
    content: ' ';
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  
  .grvsc-gutter + .grvsc-source {
    padding-left: 0.75rem;
    padding-left: calc(var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem)) / 2);
  }
  
  /* Line transformer styles */
  
  .grvsc-has-line-highlighting > .grvsc-code > .grvsc-line::before {
    content: ' ';
    position: absolute;
    width: 100%;
  }
  
  .grvsc-line-diff-add::before {
    background-color: var(--grvsc-line-diff-add-background-color, rgba(0, 255, 60, 0.2));
  }
  
  .grvsc-line-diff-del::before {
    background-color: var(--grvsc-line-diff-del-background-color, rgba(255, 0, 20, 0.2));
  }
  
  .grvsc-line-number {
    padding: 0 2px;
    text-align: right;
    opacity: 0.7;
  }
  
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      